import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing, ensureCurrentUser } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import EditListingDeafPersonForm from '../../forms/EditListingDeafPersonForm/EditListingDeafPersonForm.js';
import { ListingLink } from '../../components';
import { fetchCurrentUser } from '../../ducks/user.duck';

import css from './EditListingFeaturesPanel.module.css';

const FEATURES_NAME = 'workDomains';

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    currentUser,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const userTypes = user.attributes.profile.privateData.userTypes;
  const isUserDeaf = userTypes.includes('deafUser');
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { description, title, publicData, privateData } = currentListing.attributes;
  const category = props?.match?.params?.category || publicData?.listingtype;
  const isTrainee =
    publicData.registration == 'traineeTranslator' ||
    publicData.registration == 'traineeInterpreter' ||
    publicData.registration == 'traineeLipspeaker' ||
    publicData.registration == 'traineeDRII';
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle =
    isPublished && category == 'requirement' ? (
      <FormattedMessage id="Edit deaf person's details" />
    ) : isPublished ? (
      <FormattedMessage
        id="EditListingFeaturesPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingFeaturesPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : category == 'requirement' ? (
      <FormattedMessage id="EditListingFeaturesPanel.deafPersonsDetails" />
    ) : (
      <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
    );
  const workDomains = publicData && publicData.workDomains;
  const additionalQualifications = publicData && publicData.additionalQualifications;
  let initialValues = {};

  if (category === 'requirement') {
    initialValues = {
      description: description ? description.replace('TBC', '') : undefined,
      workDomain: publicData.workDomain,
      deafPersonName: privateData?.deafPersonName,
      deafPersonSMS: privateData?.deafPersonSMS,
      appointmentDoc: privateData?.appointmentDoc,
      bookingForSelf: privateData?.bookingForSelf,
    };
    userTypes.includes('luhft') &&
      (initialValues.contractBookingType = publicData?.contractBookingType);
  } else initialValues = { workDomains, additionalQualifications };
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {category === 'requirement' && (
        <div>
          <EditListingDeafPersonForm
            className={css.form}
            category={category}
            userTypes={userTypes}
            isUserDeaf={isUserDeaf}
            publicData={publicData}
            initialValues={initialValues}
            onSubmit={values => {
              let {
                description,
                workDomain,
                deafPersonName,
                deafPersonSMS,
                bookingForSelf,
                appointmentDoc,
                ...rest
              } = values;
              if (isUserDeaf && bookingForSelf)
                [deafPersonName, deafPersonSMS] = [privateData.title, privateData.phoneNumber];
              const updatedValues = {
                description: description || 'N/A',
                publicData: {
                  workDomain,
                },
                privateData: {
                  deafPersonName,
                  deafPersonSMS,
                  appointmentDoc,
                  bookingForSelf,
                },
              };
              userTypes.includes('luhft') &&
                (updatedValues.publicData.contractBookingType = rest.contractBookingType);
              onSubmit(updatedValues);
            }}
            onChange={onChange}
            saveActionMsg={submitButtonText}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />{' '}
        </div>
      )}
      {category !== 'requirement' && (
        <EditListingFeaturesForm
          className={css.form}
          category={category}
          name={FEATURES_NAME}
          initialValues={initialValues}
          isTrainee={isTrainee}
          onSubmit={values => {
            const { workDomains = [], additionalQualifications } = values;

            const updatedValues = {
              publicData: { workDomains, additionalQualifications },
            };
            onSubmit(updatedValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      )}
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
