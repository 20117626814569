import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { saveDoc } from '../../util/api';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import arrayMutators from 'final-form-arrays';
import { required } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldSelect,
  FieldPhoneNumberInput,
  FieldCheckbox,
  SecondaryButton,
  ValidationError,
} from '../../components';

import hand from './helping-hand-alert-transparent.png';
import css from './EditListingDeafPersonForm.module.css';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import { setUser } from '@sentry/browser';

export const EditListingDeafPersonFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        filterConfig,
        category,
        userTypes,
        isUserDeaf,
        initialValues,
        onChange,
      } = formRenderProps;
      const [documentUpload, setDocumentUpload] = useState('');
      const [successAppt, setSuccessAppt] = useState(false);
      const [apptUrl, setApptUrl] = useState('');
      const [imageAppt, setImageAppt] = useState('');
      const getFilePath = path => {
        if (path) {
          const filename = path.replace(/^.*\\/, '');
          return `Your saved file: ${filename}`;
        } else {
          return 'Please upload a file and then save the form using the button at the end of this form';
        }
      };

      const onChangeDoc = e => {
        if (e.target.files[0]) {
          const document = e.target.files[0];
          setImageAppt(document);
          setSuccessAppt(false);
        }
      };

      const onAppointmentUpload = async e => {
        e.preventDefault();
        console.log('start of document upload');

        const appointment = imageAppt;
        const date = new Date().toLocaleString('en-GB');
        const dateFormatted = date.replace(/\//g, '-');

        if (appointment === '') {
          console.error('no image chosen');
          setSuccessAppt(false);
          setImageAppt(null);
          return;
        }

        const uploadResult = await saveDoc({
          file: appointment,
          originalFilename: appointment.name,
          purpose: 'Appointments',
        });
        setApptUrl(uploadResult.fileId);
        setSuccessAppt(true);
        setDocumentUpload(`Upload reference: ${uploadResult.fileId}`);
        setImageAppt('');
      };
      const isDocumentEnabled = imageAppt.size > 1 && imageAppt != null;

      const editBookingMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.editBookingMessage',
      });
      const workDomainLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.workDomainsTitle',
      });
      const bookingForSelf = intl.formatMessage({
        id: 'EditListingDetailsForm.bookingForSelf',
      });
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
        </p>
      ) : null;
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || !(values?.tos?.length > 0);

      const options = findOptionsForSelectFilter('workDomains', filterConfig);

      // Labels
      const requiredText = intl.formatMessage({
        id: 'EditListingDetailsForm.requiredText',
      });

      // Booking description
      const bookingDescriptionLabel = intl.formatMessage(
        { id: 'EditListingDetailsForm.bookingDescriptionLabel' },
        { requiredText: requiredText }
      );
      const bookingDescriptionPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.bookingDescriptionPlaceholder',
      });

      // Deaf person's name
      const deafPersonNameLabel = intl.formatMessage(
        { id: 'EditListingDetailsForm.deafPersonNameLabel' },
        { requiredText: requiredText }
      );
      const deafPersonNamePlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.deafPersonNamePlaceholder',
      });

      //Deaf person's DOB
      const deafPersonSMSLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.deafPersonSMSLabel',
      });
      const deafPersonSMSPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.deafPersonSMSPlaceholder',
      });
      const SuccessMessage = () => (
        <div className={css.successMessage}>
          <img src={hand} alt="Signalise helping alert hand" className={css.alertHand}></img>
          <p>
            <strong>
              <FormattedMessage id="EditListingRegistrationForm.uploadSuccess" />
            </strong>
          </p>
        </div>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <div>
            {isUserDeaf && (
              <FieldCheckbox
                id="bookingForSelf"
                name="bookingForSelf"
                label={bookingForSelf}
                value="true"
              />
            )}
            {values?.['bookingForSelf']?.[0] != 'true' && (
              <>
                {' '}
                <div className={css.field}>
                  <FieldTextInput
                    className={css.building}
                    id="deafPersonName"
                    type="textarea"
                    name="deafPersonName"
                    label={deafPersonNameLabel}
                    placeholder={deafPersonNamePlaceholder}
                    validate={required('This field is required')}
                  />
                  <small>
                    <FormattedMessage id="EditListingDetailsForm.clientNameConfidentiality" />
                  </small>
                </div>
                <div className={css.field}>
                  <FieldPhoneNumberInput
                    className={css.building}
                    name="deafPersonSMS"
                    id="deafPersonSMS"
                    label={deafPersonSMSLabel}
                    placeholder={deafPersonSMSPlaceholder}
                  />
                  <small>
                    <FormattedMessage id="EditListingDetailsForm.SMSInformation" />
                  </small>
                </div>
              </>
            )}
          </div>
          <div
            className={css.field}
            style={
              userTypes.includes('contractCustomer') ? { display: 'none' } : { display: 'block' }
            }
          >
            <FieldSelect
              className={css.field}
              id="workDomain"
              name="workDomain"
              label={workDomainLabel}
              validate={required('This field is required')}
              defaultValue={userTypes.includes('contractCustomer') ? 'medical' : ''}
            >
              <option disabled value="">
                {' '}
                Choose from...
              </option>
              {options.map(c => (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              ))}
            </FieldSelect>
          </div>
          {userTypes.includes('luhft') && (
            <div className={css.field}>
              {' '}
              <FieldSelect
                className={css.field}
                id="contractBookingType"
                name="contractBookingType"
                label={'Select type of booking'}
                validate={required('This field is required')}
              >
                <option disabled value="">
                  {' '}
                  Choose from...
                </option>

                <option value="Outpatient">Outpatient</option>
                <option value="Inpatient or Day Case">Inpatient or Day Case</option>
                <option value="Emergency">Emergency</option>
                <option value="Therapies or Scan">Therapies or Scan</option>
                <option value="GP Appointment">GP Appointment</option>
                <option value="Nurse Appointment">Nurse Appointment</option>
                <option value="Home Visit">Home Visit</option>
                <option value="Community Clinic">Community Clinic</option>
              </FieldSelect>{' '}
            </div>
          )}
          <div className={css.field}>
            <FieldTextInput
              className={css.building}
              type="textarea"
              name="description"
              id="description"
              label={bookingDescriptionLabel}
              placeholder={bookingDescriptionPlaceholder}
              validate={
                !userTypes.includes('contractCustomer') && required('This field is required')
              }
            />
            <small>
              <FormattedMessage id="EditListingDetailsForm.bookingInformation" />
            </small>
          </div>
          {values?.['bookingForSelf']?.[0] == 'true' && (
            <>
              {' '}
              <small>
                <FormattedMessage id="EditListingDetailsForm.uploadDocument" />
              </small>
              <p className={css.helperText}>
                <FormattedMessage id="EditListingRegistrationForm.imageTypes" />
              </p>
              <Field component="input" id="appointmentDoc" name="appointmentDoc">
                {({ input, meta }) => (
                  <div className={css.inputContainer}>
                    <input
                      type="file"
                      className={css.upload}
                      accept=".jpg,.jpeg,.png,.pdf"
                      onChange={e => {
                        input.onChange(e); //final-form's onChange
                        if (onChange) {
                          onChangeDoc(e);
                        }
                      }}
                    />
                    <ValidationError fieldMeta={meta} />
                  </div>
                )}
              </Field>
              <SecondaryButton
                type="button"
                disabled={!isDocumentEnabled}
                onClick={onAppointmentUpload}
                className={css.uploadButton}
              >
                Upload
              </SecondaryButton>
              {successAppt ? <SuccessMessage /> : false}
              <div>
                <p className={css.helperText}>{getFilePath(initialValues.appointmentDoc)}</p>
              </div>
            </>
          )}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            // disabled={submitDisabled}
            ready={submitReady}
          >
            {category == 'requirement'
              ? saveActionMsg.toLowerCase().startsWith('save')
                ? 'Save'
                : 'Next: Your location'
              : saveActionMsg}
          </Button>
          {!userTypes.includes('contractCustomer') && (
            <div className={css.bookingInfoHelp}>
              <img src={hand} alt="Signalise helping hand alert" className={css.hand}></img>
              <p>{editBookingMessage}</p>
            </div>
          )}
        </Form>
      );
    }}
  />
);

EditListingDeafPersonFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingDeafPersonFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingDeafPersonFormComponent);
